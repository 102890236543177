import React, { createContext, useContext, useReducer } from "react";
import AuthService from "../services/AuthService";
import AdjuntosService from "../services/AdjuntosService";
import UserReducer from "../reducers/UserReducer";
import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  LOGIN,
  LOGOUT,
  SET_PROPIEDAD_USER,
  GUARDAR_USUARIO,
  EDITAR_USUARIO,
} from "../types";
import { ModalContext } from "./ModalContext";
import CustomerService from "../services/CustomerService";
import { navigate } from "@reach/router";

const initialState = {
  user: null,
  email: null,
  password: null,
  telefono: null,
  cuenta: null,
  direccion: null,
  spinner: false,
};

export const AuthContext = createContext(initialState);

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  const { success, alert } = useContext(ModalContext);

  function handleError(error) {
    dispatch({ type: HIDE_SPINNER });
    window.localStorage.setItem("facebookUserId", null);
    if (error.response) {
      if (error.response.status === 409) {
        return alert(
          `Ya tienes una cuenta con nosotros. Por favor, inicia sesión.`
        );
      }
    }
    AuthService.signOut();
  }

  function facebookSignIn(user) {
    AuthService.setToken(user.userID);
    window.localStorage.setItem("facebookUserId", user.userID);
    getUsuario();
  }

  function facebookSignUp(user) {
    dispatch({ type: SHOW_SPINNER });
    CustomerService.getCustomerByPhone(user.phone).then((res) => {
      const { customer } = res.data;
      if (customer !== null) {
        dispatch({ type: HIDE_SPINNER });
        return alert("Ya existe una cuenta con ese número de teléfono");
      }
      CustomerService.signUp({
        name: user.name,
        email: user.email,
        phone: user.phone,
        uid: user.userID,
        auth_provider: "Facebook",
      })
        .then(() => {
          AuthService.setToken(user.userID);
          dispatch({ type: HIDE_SPINNER });
          getUsuario();
        })
        .catch(handleError);
    });
  }

  function signIn(email, password) {
    dispatch({ type: SHOW_SPINNER });
    AuthService.signIn(email, password)
      .then((user) => {
        if (!user || user === null) {
          alert(
            "Ya tienes cuenta con nosotros pero la contraseña es incorrecta. Por favor, intenta de nuevo"
          );
          AuthService.signOut();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.code === "auth/user-not-found") {
          alert(
            "Lo sentimos. No encontramos una cuenta con ese email. ¡Registrate!"
          );
        }
        if (error.code === "auth/wrong-password") {
          alert("La password es incorrecta. Por favor, intenta de nuevo");
        } else {
          alert(error.toString());
        }
      });
  }

  function userLoggedIn() {
    dispatch({ type: SHOW_SPINNER });
    let facebookUserId = window.localStorage.getItem("facebookUserId");
    if (facebookUserId !== null && facebookUserId !== "null") {
      AuthService.setToken(facebookUserId);
      return getUsuario();
    }
    AuthService.userLoggedIn(
      (user) => {
        if (user !== null) {
          AuthService.getToken().then((token) => {
            AuthService.setToken(token);
            getUsuario();
          });
        }
      },
      (error) => {
        if (error) {
          alert(error);
          AuthService.signOut();
          navigate("/entrar");
        }
        dispatch({ type: HIDE_SPINNER });
      }
    );
  }

  function signOut() {
    window.localStorage.removeItem("facebookUserId");
    AuthService.signOut()
      .then(() => {
        dispatch({ type: LOGOUT });
        navigate("/entrar");
      })
      .catch(handleError);
  }

  function signUp(name, email, password, phone, signup_reason, auth_provider) {
    dispatch({ type: SHOW_SPINNER });
    CustomerService.getCustomerByPhone(phone).then((res) => {
      const { customer } = res.data;
      if (customer !== null) {
        dispatch({ type: HIDE_SPINNER });
        return alert("Ya existe una cuenta con ese número de teléfono");
      }
      AuthService.signUp(email, password)
        .then((user) => {
          const uid = user.user.uid;
          CustomerService.signUp({
            name,
            email,
            phone,
            uid,
            signup_reason,
            auth_provider,
          })
            .then(() => {
              signIn(email, password);
            })
            .catch(handleError);
        })
        .catch(handleError);
    });
  }

  function getUsuario() {
    CustomerService.getCurrentCustomer()
      .then((res) => {
        const { customer } = res.data;
        dispatch({ type: HIDE_SPINNER });
        dispatch({ type: LOGIN, payload: customer });
      })
      .catch(handleError);
  }

  function editarUsuario() {
    dispatch({ type: EDITAR_USUARIO });
  }

  function cancelEdit() {
    dispatch({ type: GUARDAR_USUARIO });
  }

  function setPropiedadUser(key, value) {
    if (key === "idAdjunto") {
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key: "file", value } });
      if (!value)
        dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    } else {
      if (key === "telefono") {
        value = String(value).replace(/\D/g, "");
        value = String(value).substring(0, 10);
      }
      dispatch({ type: SET_PROPIEDAD_USER, payload: { key, value } });
    }
  }

  function recoverPassword(email) {
    AuthService.recoverPassword(email)
      .then(() => {
        success("Te hemos enviado un email para reestablecer tu password.");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          CustomerService.recoverPassword(email).then(() => {
            success(
              "¡Te hemos enviado un email para reestablecer tu password!"
            );
          });
        } else {
          alert("Hubo un error al enviar el email. Intentalo mas tarde.");
        }
      });
  }

  function updateUsuario(usuario) {
    const promises = [];
    if (usuario.file && usuario.file !== null) {
      if (usuario.file.name) {
        const promiseAdjunto = new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("adjunto", usuario.file);
          AdjuntosService.postAdjunto(formData).then((res) => {
            const { idAdjunto } = res.data;
            usuario.idAdjunto = idAdjunto;
            resolve();
          });
        });
        promises.push(promiseAdjunto);
      }
    }
    Promise.all(promises).then(() => {
      const data = { ...usuario };
      delete data.file;
      delete data.uid;
      delete data.activo;
      CustomerService.putCurrentCustomer(data)
        .then((res) => {
          dispatch({ type: GUARDAR_USUARIO });
          success("Perfil actualizado con exito.");
        })
        .catch(handleError);
    });
  }

  const verifyEmail = () => {
    AuthService.verifyEmail()
      .then(() => {
        success("Te enviamos un email para confirmar tu direccion");
      })
      .catch(handleError);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut,
        getUsuario,
        cancelEdit,
        verifyEmail,
        userLoggedIn,
        updateUsuario,
        editarUsuario,
        facebookSignIn,
        facebookSignUp,
        recoverPassword,
        setPropiedadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
